import { ContextualMenu } from "@fluentui/react";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";
import { navigationLinkInterface } from "./GosNavigationInterfaces";

interface Props {
  closeAppLuncher: () => void;
  index: any;
  link: any;
  id?: string;
}

function NavigationComponent({ index, link, closeAppLuncher, id }: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [show, setShow] = useState<boolean>(true);
  const iconRef = useRef(null);

  const navigationItemWrapStyle = (
    link: navigationLinkInterface,
    index: number,
    id?: string
  ): React.CSSProperties => {
    return {
      position: "relative",
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      width: "40%",
      height: "50px",
      pointerEvents: link.disabled ? "none" : "auto",
    };
  };

  const iconStyle = (link: navigationLinkInterface): React.CSSProperties => {
    return {
      opacity: link.disabled ? "0.3" : "1",
    };
  };

  const linkNameStyle = (
    link: navigationLinkInterface
  ): React.CSSProperties => {
    return {
      paddingLeft: "10px",
      opacity: link.disabled ? "0.3" : "1",
    };
  };

  return (
    <div
      id={id}
      key={index}
      style={navigationItemWrapStyle(link, index)}
      onClick={() => {
        if (!link.disabled) {
          if (link.openInNewTab) {
            window.open(link.path, "_blank");
          } else {
            navigate(link.path, {
              state: {
                prevRoute: location.pathname,
              },
            });
          }
          closeAppLuncher();
        }
      }}
      ref={iconRef}
      onContextMenu={
        link.openInNewTab
          ? undefined
          : (e) => {
              setShow(false);
              e.preventDefault();
            }
      }
    >
      <div style={iconStyle(link)}>{link.icon()}</div>
      <div style={linkNameStyle(link)}>{link.name}</div>
      <ContextualMenu
        items={[
          {
            key: "newItem",
            text: t("clm.openNewTab.contextMenu.label"),
            onClick: () => {
              window.open(link.path, "_blank");
            },
          },
        ]}
        hidden={show}
        target={iconRef}
        onDismiss={() => setShow(true)}
        onItemClick={() => setShow(true)}
      />
    </div>
  );
}

export default NavigationComponent;
