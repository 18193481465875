import { IOverflowSetItemProps } from "@fluentui/react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { LocationsIcon } from "../componentIcons/LocationsIcon";
import { RvmIcon } from "../componentIcons/RvmIcon";
import { CrmSideBarContext } from "./CrmSideBarContext";
import { ClaimIcon } from "./componentIcons/ClaimIcon";
import { CorporateClientIcon } from "./componentIcons/CorporateClientIcon";
import { DepartmentIcon } from "./componentIcons/DepartmentIcon";
import { DmsIcon } from "./componentIcons/DmsIcon";
import { FleetIcon } from "./componentIcons/FleetIcon";
import { MandateIcon } from "./componentIcons/MandateIcon";
import { PolicyIcon } from "./componentIcons/PolicyIcon";
import { PremiumClientIcon } from "./componentIcons/PremiumClientIcon";
import { ProjectIcon } from "./componentIcons/ProjectIcon";
import { SubClientsIcon } from "./componentIcons/SubClientsIcon";
import { TmsIcon } from "./componentIcons/TmsIcon";
import WebReportsIcon from "./componentIcons/WebReportsIcon";

interface sidebarData {
  ["BusinessObjectType.CompanyClient"]: IOverflowSetItemProps[];
  ["BusinessObjectType.PrivateClient"]: IOverflowSetItemProps[];
  ["BusinessObjectType.Mandate"]: IOverflowSetItemProps[];
}

interface IProps {
  type: string;
  id: number;
}

export const CrmSidebarData = (props: IProps) => {
  const { state } = useContext(CrmSideBarContext);
  const { t } = useTranslation();
  const gosUrl = process.env.REACT_APP_GOS_BASE_URL;
  const baseUrl = ""; //process.env.REACT_APP_URL;

  const data: any = {
    ["BusinessObjectType.CompanyClient"]: [
      {
        key: "corporateClient",
        name: "",
        title: t("rvm.client.sideBarCorporateClient"),
        openInNewTab: () => {
          window.open(gosUrl + `/crm/client/corporate/${props.id}`, "_blank");
        },
        onClick: () => {
          window.open(gosUrl + `/crm/client/corporate/${props.id}`, "_self");
        },
        icon: () => {
          return <CorporateClientIcon width="25" height="25" />;
        },
      },
      {
        key: "Mandate",
        name: "",
        title: t("rvm.client.sideBarMandate"),
        openInNewTab: () => {
          window.open(
            gosUrl + `/crm/client/corporate/mandate/list/${props.id}`,
            "_blank"
          );
        },
        onClick: () => {
          window.open(
            gosUrl + `/crm/client/corporate/mandate/list/${props.id}`,
            "_self"
          );
        },
        disabled: false,
        icon: () => {
          return <MandateIcon width="28" height="28" />;
        },
      },
      {
        key: "Location",
        name: "",
        title: t("rvm.client.sideBarLocation"),
        openInNewTab: () => {
          window.open(
            gosUrl + `/crm/client/corporate/locations/${props.id}`,
            "_blank"
          );
        },
        onClick: () => {
          window.open(
            gosUrl + `/crm/client/corporate/locations/${props.id}`,
            "_self"
          );
        },
        disabled: false,
        icon: () => {
          return <LocationsIcon width="28" height="30" />;
        },
      },
      {
        key: "Rvm",
        name: "",
        title: t("rvm.client.sideBarValueMonitoring"),
        openInNewTab: () => {
          //window.open(baseUrl + `/locations?clientId=${props.id}`, "_blank");
        },
        onClick: () => {
          //window.open(baseUrl + `/locations?clientId=${props.id}`, "_self");
        },
        disabled: false, //isClientSidebarNavigationDisabled,
        icon: () => {
          return <RvmIcon width="26" height="26" />;
        },
      },

      {
        key: "Fleets",
        name: "",
        title: t("rvm.fleets.label"),
        openInNewTab: () => {
          const path = process.env.REACT_APP_BASE_URL_FLEETS || "/fleets";

          window.open(path, "_blank");
        },
        onClick: () => {
          const path = process.env.REACT_APP_BASE_URL_FLEETS || "/fleets";

          window.open(path, "_self");
        },
        disabled: false,
        icon: () => {
          return <FleetIcon width="31" height="26" />;
        },
      },

      {
        key: "Policy",
        name: "",
        title: t("rvm.client.sideBarPolicy"),
        disabled: false,
        openInNewTab: () => {
          window.open(
            gosUrl + `/policies/?organisationoid=${props.id}`,
            "_blank"
          );
        },
        onClick: () => {
          window.open(
            gosUrl + `/policies/?organisationoid=${props.id}`,
            "_self"
          );
        },
        icon: () => {
          return <PolicyIcon width="26" height="26" />;
        },
      },
      {
        key: "Claim",
        name: "",
        title: t("rvm.client.sideBarClaim"),
        openInNewTab: () => {
          window.open(
            gosUrl + `/claims/?organisationoid=${props.id}`,
            "_blank"
          );
        },
        onClick: () => {
          window.open(gosUrl + `/claims/?organisationoid=${props.id}`, "_self");
        },
        disabled: false,
        icon: () => {
          return <ClaimIcon width="28" height="28" />;
        },
      },
      {
        key: "Document",
        name: "",
        title: t("rvm.client.sideBarDocuments"),
        openInNewTab: () => {
          window.open(
            gosUrl +
              `/document/?cid=${props.id}&ctype=Partner&ptype=CompanyClient`,
            "_blank"
          );
        },
        disabled: false,
        onClick: () => {
          window.open(
            gosUrl +
              `/document/?cid=${props.id}&ctype=Partner&ptype=CompanyClient`,
            "_self"
          );
        },
        icon: () => {
          return <DmsIcon width="28" height="28" />;
        },
      },
      {
        key: "Task",
        name: "",
        title: t("rvm.client.sideBarTasks"),
        openInNewTab: () => {
          window.open(
            gosUrl + `/task/?corporateclient=${props.id}&type=CorporateClient`,
            "_blank"
          );
        },
        disabled: false,
        onClick: () => {
          window.open(
            gosUrl + `/task/?corporateclient=${props.id}&type=CorporateClient`,
            "_self"
          );
        },
        icon: () => {
          return <TmsIcon width="28" height="28" />;
        },
      },
      {
        key: "Department",
        name: "",
        title: t("rvm.client.sideBarDepartments"),
        openInNewTab: () => {
          window.open(
            gosUrl + `/crm/client/corporate/departments/${props.id}`,
            "_blank"
          );
        },
        onClick: () => {
          window.open(
            gosUrl + `/crm/client/corporate/departments/${props.id}`,
            "_self"
          );
        },
        icon: () => {
          return <DepartmentIcon width="25" height="25" />;
        },
      },
      {
        key: "Project",
        name: "",
        title: t("rvm.client.sideBarProjects"),
        openInNewTab: () => {
          window.open(
            gosUrl + `/crm/client/corporate/projects/${props.id}`,
            "_blank"
          );
        },
        disabled: false,
        onClick: () => {
          window.open(
            gosUrl + `/crm/client/corporate/projects/${props.id}`,
            "_self"
          );
        },
        icon: () => {
          return <ProjectIcon width="28" height="28" />;
        },
      },
      {
        key: "Premium",
        name: "",
        title: t("rvm.client.sideBarPremiumAccounts"),
        openInNewTab: () => {
          window.open(
            gosUrl + `/crm/client/corporate/premium-accounts/${props.id}`,
            "_blank"
          );
        },
        disabled: false,
        onClick: () => {
          window.open(
            gosUrl + `/crm/client/corporate/premium-accounts/${props.id}`,
            "_self"
          );
        },
        icon: () => {
          return <PremiumClientIcon width="28" height="28" />;
        },
      },
      {
        key: "Subclients",
        name: "",
        title: t("rvm.client.sideBarSubclients"),
        openInNewTab: () => {
          window.open(gosUrl + `/crm/client/subclients/${props.id}`, "_blank");
        },
        onClick: () => {
          window.open(gosUrl + `/crm/client/subclients/${props.id}`, "_self");
        },
        icon: () => {
          return <SubClientsIcon width="28" height="28" />;
        },
      },
      {
        key: "WebReports",
        name: "",
        title: t("rvm.client.sideBarWebReport"),
        openInNewTab: () => {
          window.open(
            gosUrl + `/crm/client/corporate/webreports/${props.id}`,
            "_blank"
          );
        },
        onClick: () => {
          window.open(
            gosUrl + `/crm/client/corporate/webreports/${props.id}`,
            "_self"
          );
        },
        disabled: false,
        icon: () => {
          return <WebReportsIcon width="28" height="28" />;
        },
      },
    ],
    ["BusinessObjectType.Location"]: [
      {
        key: "LocationCorporate",
        name: "",
        title: t("crm.client.sideBarLocationCorporate"),
        openInNewTab: () => {
          props.id &&
            window.open(
              gosUrl + `/crm/location/corporate/readonly/${props.id}`,
              "_blank"
            );
        },
        onClick: () => {
          props.id &&
            window.open(
              gosUrl + `/crm/location/corporate/readonly/${props.id}`,
              "_self"
            );
        },
        icon: () => {
          return <LocationsIcon width="26" height="30" />;
        },
      },
      {
        key: "Rvm",
        name: "",
        title: t("rvm.client.sideBarValueMonitoring"),
        openInNewTab: () => {
          //window.open(baseUrl + `/locations?clientId=${props.id}`, "_blank");
        },
        onClick: () => {
          //window.open(baseUrl + `/locations?clientId=${props.id}`, "_self");
        },
        disabled: false, //isClientSidebarNavigationDisabled,
        icon: () => {
          return <RvmIcon width="26" height="26" />;
        },
      },
    ],
  };

  return data[props.type];
};
